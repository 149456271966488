import React from "react";
import { Route, Router } from "wouter";
import ExternalPlayer from "./ExternalPlayer";
import Player from "./Player";
import ErrorBoundary from "./ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Route path="/playlist/:creativeId">
          {(params) => (
            <Player creativeId={params.creativeId} playlist={true} />
          )}
        </Route>
        <Route path="/external/creatives" component={ExternalPlayer} />
        <Route path="/player/:creativeId">
          {(params) => <Player creativeId={params.creativeId} />}
        </Route>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
